import { useState } from 'react';
import { createContainer } from '~/modules/unstated-next-utils/createContainer';
import { toPercentage } from '~/utils/toPercentage';
import { useVolumeAmountStore } from '~/modules/ranking-realtime/useVolumeAmountStore';
import { useDatePick } from '../useDatePick';
import { useBig3ConsecutiveResource, useExtremeVolumeResource, usePercentRangeResource, useTurnoverRateResource, useWarrantChipResource, useZScoreResource, } from '~/modules/screener/containers/useStockScreenerResource';
import { useSnapshot } from 'valtio';
export const useCondition = function useCondition(props) {
    /** 由於後端不一定來得及拿到，所以還是需要前端預設值以防萬一 */
    const pickedDate = useDatePick();
    const volumeAmountState = useSnapshot(useVolumeAmountStore);
    const [volumeRange, amountRange] = [
        [volumeAmountState.volumeGreaterThan, volumeAmountState.volumeLessThan],
        [volumeAmountState.amountGreaterThan, volumeAmountState.amountLessThan],
    ];
    const [date, setDate] = useState(pickedDate);
    const [days, setDays] = useState(10);
    const [institution, setInstitution] = useState('foreigner');
    const [percentRangeStatus, setPercentRangeStatus] = useState('new_high');
    const [action, setAction] = useState('buy');
    const [power, setPower] = useState('strong');
    const [threshold, setThreshold] = useState(0.2);
    const [currentResource, setCurrentResource] = useState(resources[0]);
    const [currentSymbol, setCurrentSymbol] = useState('TSEA');
    const [warrantType, setWarrantType] = useState('call');
    /** 要麻顯示data，要馬使用者想要自己比較資訊就使用這個 */
    const [selfComparionData, setSelfComparionData] = useState([]);
    const { data } = currentResource.hook({
        date,
        days,
        institution,
        action,
        threshold,
        status: percentRangeStatus,
        type: power,
        volume_gte: volumeRange[0],
        volume_lte: volumeRange[1],
        amount_gte: amountRange[0],
        amount_lte: amountRange[1],
        warrantType,
    });
    return {
        state: {
            currentSymbol,
            resources,
            date,
            days,
            institution,
            action,
            power,
            threshold,
            percentRangeStatus,
            currentResource,
            data,
            selfComparionData,
            warrantType,
        },
        acts: {
            setCurrentSymbol,
            setDate,
            setDays,
            setInstitution,
            setAction,
            setPower,
            setThreshold,
            setPercentRangeStatus,
            setCurrentResource,
            setSelfComparionData,
            setWarrantType,
        },
    };
};
/** 用來記錄與UI互動的相關參數，與useStockselectorResource搭配使用 */
export const useConditionState = createContainer(useCondition);
/** 三大Mapper */
const big3ConsecutiveMapper = (item, ref) => ({
    id: item.symbol,
    symbol: item.symbol,
    strength: toPercentage(Math.abs(item.diff_volume_percentage), Math.abs(ref)),
    diff_volume: item.diff_volume,
    diff_volume_percentage: item.diff_volume_percentage,
});
/** 三大Sorter */
const big3ConsecutiveSorter = (a, b) => Math.abs(b.diff_volume_percentage) - Math.abs(a.diff_volume_percentage);
/** 週轉Mapper */
const turnoverRateMapper = (item, ref) => ({
    id: item.symbol,
    symbol: item.symbol,
    strength: toPercentage(item.turnover_rate, ref),
    turnoverRate: item.turnover_rate.toFixed(2),
    avgTurnoverRate: item.avg_turnover_rate.toFixed(2),
});
/** 週轉Sorter */
const turnoverRateSorter = (a, b) => b.turnover_rate - a.turnover_rate;
/** 爆量Mapper */
const extremeVolumeMapper = (item, ref) => ({
    id: item.symbol,
    symbol: item.symbol,
    strength: toPercentage(item.volume, ref),
    volumeMa: item.volume_ma,
    volume: item.volume,
});
/** 爆量Sorter */
const extremeVolumeSorter = (a, b) => b.volume - a.volume;
/** 價格區間Mapper */
const percentRangeMapper = (item, ref) => ({
    id: item.symbol,
    symbol: item.symbol,
    strength: toPercentage(item.percentage, ref),
});
/** 價格區間Sorter */
const percentRangeSorter = (a, b) => b.percentage - a.percentage;
/** 強弱Mapper */
const zScoreMapper = (item, ref) => ({
    id: item.symbol,
    symbol: item.symbol,
    strength: toPercentage(item.z_score, ref),
});
/** 強弱Sorter */
const zScoreSorter = (a, b) => Math.abs(b.z_score) - Math.abs(a.z_score);
/** 權證篩選Mapper */
const warrantChipMapper = (item, ref) => ({
    id: item.code,
    date: item.date,
    symbol: item.code,
    name: item.name,
    type: item.type,
    strength: toPercentage(item.bs_value, ref),
    diffQty: item.bs_volume,
    diffValue: item.bs_value,
});
/** 權證篩選Sorter */
const warrantChipSorter = (a, b) => {
    b.bs_value - a.bs_value;
};
const resources = [
    {
        title: '趨勢轉折',
        resourceKey: 'z_score',
        mapper: zScoreMapper,
        sorter: zScoreSorter,
        hook: useZScoreResource,
        referer: (datum) => Math.abs(datum.z_score),
    },
    {
        title: '法人籌碼',
        resourceKey: 'big3_consecutive',
        mapper: big3ConsecutiveMapper,
        sorter: big3ConsecutiveSorter,
        hook: useBig3ConsecutiveResource,
        referer: (datum) => Math.abs(datum.diff_volume_percentage),
    },
    {
        title: '週轉率',
        resourceKey: 'turnover_rate',
        mapper: turnoverRateMapper,
        sorter: turnoverRateSorter,
        hook: useTurnoverRateResource,
        referer: (datum) => datum.turnover_rate,
    },
    {
        title: '成交量增',
        resourceKey: 'extreme_volume',
        mapper: extremeVolumeMapper,
        sorter: extremeVolumeSorter,
        hook: useExtremeVolumeResource,
        referer: (datum) => datum.volume,
    },
    {
        title: '價格區間',
        resourceKey: 'percent_range',
        mapper: percentRangeMapper,
        sorter: percentRangeSorter,
        hook: usePercentRangeResource,
        referer: (datum) => datum.percentage,
    },
    {
        title: '權證籌碼',
        resourceKey: 'warrant_chip',
        mapper: warrantChipMapper,
        sorter: warrantChipSorter,
        hook: useWarrantChipResource,
        referer: (datum) => Math.abs(datum.bs_value),
    },
];
